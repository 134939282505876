import React, { Component } from "react";
import MediaQuery from "react-responsive";

function initYoutube() {
  var vidDefer = document.getElementsByTagName("iframe");
  for (var i = 0; i < vidDefer.length; i++) {
    if (vidDefer[i].getAttribute("data-src")) {
      vidDefer[i].setAttribute("src", vidDefer[i].getAttribute("data-src"));
    }
  }
}

class Video extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("load", initYoutube());
    }
  }
  render() {
    return (
      <div className="bg-gray-50">
        <div className="flex flex-col max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase text-center">
              PWA Explained
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center">
              What is a Progressive Web App?
            </p>
            <p className="mt-4 max-w-3xl text-xl text-gray-500 lg:mx-auto text-center">
              Learn what is a Progressive Web App in just 60 seconds.
            </p>
          </div>
          <div className="max-w-3xl mx-auto text-center mt-4">
            <MediaQuery minWidth={840}>
              <iframe
                title="Gatsby Storefront vs Shopify: speed test comparison of GatsbyJS based web store"
                width="840"
                height="472.5"
                data-src="https://www.youtube.com/embed/Z8MjdQGyjfA?controls=0"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </MediaQuery>
            <MediaQuery maxWidth={840}>
              <iframe
                title="Gatsby Storefront vs Shopify: speed test comparison of GatsbyJS based web store mobile"
                width="350"
                data-src="https://www.youtube.com/embed/Z8MjdQGyjfA?controls=0"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
