import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Hero from "../components/SharedTailwind/PWA/Hero";
import Features from "../components/SharedTailwind/PWA/Features";
import Video from "../components/SharedTailwind/PWA/Video";
import ContactForm from "../components/SharedTailwind/ContactForm";

function PWAPage(props) {
  return (
    <React.Fragment>
      <Helmet title="PWA for Shopify">
        <meta
          name="description"
          content="Create a PWA for Shopify with Gatsby JS."
        />
      </Helmet>
      <Hero />
      <Features />
      <Video />
      <ContactForm title="Contact us for PWA development" description="" />
    </React.Fragment>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default PWAPage;
