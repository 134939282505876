import React from "react";

function Features() {
  return (
    <div className="bg-white  bg-gradient-to-r from-blue-800 to-blue-700">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-white">
            Why create a Progressive Web App for Shopify?
          </h2>
          <p className="mt-4 text-lg text-blue-200">
            PWAs provide an opportunity to increase your audience. Research has
            shown that while shopping, if customers face connectivity issues or
            slow page loading time, in most cases, they will abandon the product
            altogether.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-white">
                Increase your audience
              </dt>
              <dd className="mt-2 text-base text-blue-200">
                PWAs provide an opportunity to increase your audience.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-white">
                Better ranking and conversions
              </dt>
              <dd className="mt-2 text-base text-blue-200">
                PWA will help get better conversions, lower bounce rates, and
                better SEO rankings..
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-white">
                Installable
              </dt>
              <dd className="mt-2 text-base text-blue-200">
                PWA can be easily installed by clicking on the ‘Add to home
                screen’ button.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-white">
                Cost-effective
              </dt>
              <dd className="mt-2 text-base text-blue-200">
                The development cost of progressive web apps is much lower than
                the development cost of native apps.
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default Features;
